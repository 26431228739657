<template>
	<div class="">
		<el-dialog
			v-loading="listLoading"
			:visible.sync="isActiveSeeCashDetailVisible"
			width="900px"
			inline
			:before-close="handleCancel">
			<span slot="title" style="font-size: 14px;color: #606266;font-weight: 700;">提现订单明细</span>
			
			<el-table
				size="small"
				border
				v-loading="listLoading"
				:header-cell-style="{'text-align':'center'}"
				:data="tableData"
				style="width: 100%">
				<el-table-column
					fixed
					label="编号"
					type="index"
					align="center"
					width="50">
				</el-table-column>
				<el-table-column
					fixed
					prop="type_name"
					label="提现类别"
					align="center"
					width="100">
				</el-table-column>
				<el-table-column
					prop="diag_no"
					label="问诊编号"
					align="center"
					width="240">
				</el-table-column>
				<el-table-column
					prop="bill_sn"
					label="结算编号"
					align="center"
					width="160">
				</el-table-column>
				<el-table-column
					prop="amount"
					label="收入"
					align="center">
				</el-table-column>
				<el-table-column
					prop="created_at"
					label="提现申请时间"
					align="center"
					width="220">
				</el-table-column>
			</el-table>
			<pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
									@pagination="getList"/>
		
		
		</el-dialog>
	</div>
</template>

<script>
import {getAdminBillsIncomes} from '@/api/finance'

import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
	},
  name: "DialogSeeCashDetail",
	props: {
    isActiveSeeCashDetailVisible: {
      type: Boolean,
			default: false,
 		},
		idBill: {
      type: Number,
		}
	},
	created () {
    this.getList()
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      total: 0,
      listLoading: false,
      tableData: [],
		}
	},
	methods: {
    getList (rest) {
      if (rest == 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      let params = { id: this.idBill }
      params.page = this.listQuery.page
      params.page_size = this.listQuery.limit
      getAdminBillsIncomes(params).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          this.total = _data.total
          this.listQuery.limit = Number(_data.per_page)
          this.tableData = _data.list
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    handleCancel (e) {
      this.$emit('update:isActiveSeeCashDetailVisible', false)
    },
	}
}
</script>

<style scoped>

</style>
