<template>
  <div class="">
    <el-dialog
      v-loading="listLoading"
      :visible.sync="dialogIncomeVisible"
      width="900px"
      inline
      :before-close="handleCancel"
    >
      <span
        slot="title"
        style="font-size: 14px; color: #606266; font-weight: 700"
        >提现订单明细</span
      >

      <el-table
        size="small"
        border
        v-loading="listLoading"
        :header-cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          fixed
          label="来源医师"
          prop="doctor_name"
          align="center"
          width="160"
        >
        </el-table-column>

        <el-table-column
          fixed
          label="订单编号"
          prop="order_sn"
          align="center"
          width="160"
        >
        </el-table-column>
        <el-table-column
          prop="amount"
          align="center"
          label="收入金额"
          width="130"
        >
        </el-table-column>
        <!-- <el-table-column
                prop="status_name"
                align="center"
                label="状态"
                width="120">
                </el-table-column> -->
        <el-table-column prop="doctor_name" label="状态" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.status == 0">
              <el-tooltip placement="top">
                <div slot="content">订单未完成</div>
                <el-tag type="danger">冻结</el-tag>
                <!-- <span style="display: inline-block;margin-left: 5px;color: #999;">查看原因</span> -->
              </el-tooltip>
            </div>
            <div v-else>
              <el-tag type="primary">转入余额</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="type_name"
          align="center"
          label="类型"
          width="130"
        >
        </el-table-column>

        <el-table-column prop="created_at" align="center" label="时间">
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </el-dialog>
  </div>
</template>

<script>
import { proxyIncomeList } from "@/api/finance";

import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  name: "ProxyIncomeDetail",
  props: {
    dialogIncomeVisible: {
      type: Boolean,
      default: false,
    },
    proxyId: {
      type: Number,
    },
  },
  created() {
    this.getList();
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      listLoading: false,
      tableData: [],
    };
  },
  methods: {
    getList(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = { id: this.idBill };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      proxyIncomeList(this.proxyId, params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    handleCancel(e) {
      this.$emit("update:dialogIncomeVisible", false);
    },
  },
};
</script>

<style scoped>
</style>
