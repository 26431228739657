<template>
  <div class="gg-container">
    <div class="search-container">
      <div class="search-container-fn-input" v-if="data_type == 1">
        <label>机构名称：</label>
        <el-input
          style="width: 200px"
          size="small"
          placeholder="请输入机构名"
          prefix-icon="el-icon-search"
          v-model="searchParams.agent_name"
          clearable
          @change="reset"
        >
        </el-input>
      </div>
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px; margin-right: 20px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="reset"
			>
				<span>查找</span>
			</el-button>
      <div class="search-container-fn-input" v-if="data_type == 1">
        <label>医生姓名：</label>
        <el-input
          style="width: 200px"
          size="small"
          placeholder="请输入医生姓名"
          prefix-icon="el-icon-search"
          v-model="searchParams.doctor_name"
          clearable
          @change="reset"
        >
        </el-input>
      </div>
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px; margin-right: 20px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="reset"
			>
				<span>查找</span>
			</el-button>
      <div class="search-container-fn-input" v-if="data_type == 2">
        <label>代理姓名：</label>
        <el-input
          style="width: 200px"
          size="small"
          placeholder="请输入代理姓名"
          prefix-icon="el-icon-search"
          v-model="searchParams.proxy_name"
          clearable
          @change="reset"
        >
        </el-input>
      </div>
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('examine') > -1">
          <el-button
            size="mini"
            type="primary"
            style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
            @click="handleCashPass(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
          >
            <i class="iconfont icon-daoru" style="font-size: 13px" />
            <span style="margin-left: 5px; font-size: 12px">通过</span>
          </el-button>
        </div>
        <div class="search-container-fn-input"  v-if="operateList.indexOf('examine') > -1">
          <el-button
            size="mini"
            type="primary"
            style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
            @click="handleCashRefuse(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
          >
            <i class="iconfont icon-daoru" style="font-size: 13px" />
            <span style="margin-left: 5px; font-size: 12px">拒绝</span>
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('export') > -1">
          <el-button
            size="mini"
            type="primary"
            style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
            @click="handleExportPayOrder()"
          >
            <i class="iconfont icon-daoru" style="font-size: 13px" />
            <span style="margin-left: 5px; font-size: 12px">导出</span>
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <div style="margin-bottom: 20px;">
      <el-radio-group v-model="data_type" @change="changeMenu">
        <el-radio-button
          v-for="item in dataOptions"
          :label="item.value"
          :key="item.value"
          >{{ item.label }}</el-radio-button
        >
      </el-radio-group>
    </div>
    <el-table
      v-if="data_type == 1"
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="handleSeeCashDetail(scope.$index, scope.row)"
            >
              查看
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
              class="switch"
              @change="changeExamine(scope.row)"
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#C8C8C8"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a v-if="column.column_prop == 'supervise_status'">
              {{ scope.row.supervise_status | formatSuperviseStatus }}
            </a>
            <a v-else-if="column.column_prop == 'status'">
              {{ scope.row.status == "0" ? "结算中" : scope.row.status_name }}
            </a>
            <a v-else>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-date-picker
                v-if="'created_at' == column.column_prop"
                v-model="createdTime"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="dateChange"
              >
              </el-date-picker>
              <el-select
                v-if="'status' == column.column_prop"
                style="width: 200px"
                @change="handleSearch(column.column_prop, searchParams.status)"
                v-model="searchParams.status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in billStatusSelect"
                  :key="item.status"
                  :label="item.label"
                  :value="item.status"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="'pres_no' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.pres_no"
                clearable
                @change="handleSearch(column.column_prop, searchParams.pres_no)"
              >
              </el-input>
              <el-input
                v-if="'bill_sn' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.bill_sn"
                clearable
                @change="handleSearch(column.column_prop, searchParams.bill_sn)"
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <el-table
      v-if="data_type == 2"
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableDataIncome"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="handleIncome(scope.row)"
            >
              查看
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
              class="switch"
              @change="changeExamine(scope.row)"
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#C8C8C8"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a v-if="column.column_prop == 'supervise_status'">
              {{ scope.row.supervise_status | formatSuperviseStatus }}
            </a>
            <a v-else-if="column.column_prop == 'status'">
              {{ scope.row.status == "0" ? "结算中" : scope.row.status_name }}
            </a>
            <a v-else>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-date-picker
                v-if="'created_at' == column.column_prop"
                v-model="createdTime"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="dateChange"
              >
              </el-date-picker>
              <el-select
                v-if="'status' == column.column_prop"
                style="width: 200px"
                @change="handleSearch(column.column_prop, searchParams.status)"
                v-model="searchParams.status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in billStatusSelect"
                  :key="item.status"
                  :label="item.label"
                  :value="item.status"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="'pres_no' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.pres_no"
                clearable
                @change="handleSearch(column.column_prop, searchParams.pres_no)"
              >
              </el-input>
              <el-input
                v-if="'bill_sn' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.bill_sn"
                clearable
                @change="handleSearch(column.column_prop, searchParams.bill_sn)"
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <DialogSeeCashDetail
      :isActiveSeeCashDetailVisible.sync="isActiveSeeCashDetailVisible"
      v-if="isActiveSeeCashDetailVisible"
      :idBill="idBill"
    ></DialogSeeCashDetail>

    <ProxyIncomeDetail
      :dialogIncomeVisible.sync="dialogIncomeVisible"
      v-if="dialogIncomeVisible"
      :proxyId="proxyId"
    ></ProxyIncomeDetail>

    <el-dialog
      v-loading="listLoading"
      :visible.sync="isActiveRefuseCashVisible"
      width="640px"
      inline
      :before-close="handleCancel"
    >
      <span
        slot="title"
        style="font-size: 14px;color: #606266;font-weight: 700;"
        >拒绝原因</span
      >

      <el-input
        type="textarea"
        :autosize="{ minRows: 3, maxRows: 20 }"
        placeholder="请输入拒绝原因"
        v-model.trim="refuseTextarea"
      >
      </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button class="mgr-40" size="mini" @click="handleCancel"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="handleConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex(type)"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="type"
    >
    </editTableHead>
    <exportSelectTable
        :isActiveSelectTable.sync="isActiveSelectTable"
        @_getAdminFieldIndex="_getAdminFieldIndex"
        :views_type="data_type"
        v-if="isActiveSelectTable"
        @exportSelectTable="exportSelectTable"
    />
  </div>
</template>

<script>
import {
  getAdminBills,
  putAdminBillsExamine,
  postAdminBillsExportIncome,
  getproxyBillsList,
  examineProxyBill,
} from "@/api/finance";
import Pagination from "@/components/Pagination";
import DialogSeeCashDetail from "./components/DialogSeeCashDetail";
import ProxyIncomeDetail from "./components/ProxyIncomeDetail";
import editTableHead from "@/components/editTableHead/editTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import { mapState } from "vuex";
import exportSelectTable from "@/components/exportSelectTable/exportSelectTable.vue";
import {exportPres} from "@/api/informationSearch";
export default {
  components: {
    exportSelectTable,
    Pagination,
    DialogSeeCashDetail,
    ProxyIncomeDetail,
    editTableHead,
  },
  name: "cashOperation",
  async created() {
    this.getList();
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  filters: {
    billStatus(v) {
      switch (v) {
        case 0:
          v = "结算中";
          break;
        case 1:
          v = "已结算";
          break;
        case 2:
          v = "已拒绝";
          break;
        default:
          v = "未知";
      }
      return v;
    },
  },
  computed: {
    ...mapState({
      table_options: (state) => state.user.table_options,
    }),
  },
  data() {
    return {
      isActiveSelectTable: false,
      operateList: [],
      multipleSelectionPres: [],
      // multipleSelection1: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "status",
          isSearch: false,
        },
        {
          name: "bill_sn",
          isSearch: false,
        },
        {
          name: "created_at",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      isActiveEditTableHead: false,
      proxyId: 0,
      data_type: 1,
      dataOptions: [
        { label: "医生提现", value: 1 },
        { label: "代理提现", value: 2 },
      ],
      dialogIncomeVisible: false,
      isActiveSeeCashDetailVisible: false,
      isActiveRefuseCashVisible: false,
      searchParams: {
        agent_name: "",
        doctor_name: "",
        status: "",
        bill_sn: "",
        created_at: "",
      },
      // payCount: {
      //   refundAmount: '0.00',
      //   payAmount: '0.00',
      // },
      datetimerange: "",
      billStatusSelect: [
        {
          status: 0,
          label: "结算中",
        },
        {
          status: 1,
          label: "已结算",
        },
        {
          status: 2,
          label: "已拒绝",
        },
      ],
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      listLoading: false,
      downloadLoading: false,
      tableData: [],
      idBill: "",
      curRow: {},
      refuseTextarea: "",
      createdTime: "",
      tableDataIncome: [],
      type: "cashOperation",
    };
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
      let tmp = val.map((v, k, a) => {
        return v.appraise_id;
      });
      this.multipleSelection = `[${tmp.join(",")}]`;
      console.log(this.multipleSelection, 43435435);
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex(list) {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: type,
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = list;
        // console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    handleIncome(row) {
      this.proxyId = row.proxy_id;
      this.dialogIncomeVisible = true;
    },
    changeMenu(e) {
      this.data_type = e;
      if (e == 1) {
        this.type = "cashOperation";
      } else {
        this.type = "agency";
      }
      this.getList();
    },
    getList(type) {
      if (type == "rest") {
        this.searchParams.page = 1;
      }
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.limit = this.listQuery.limit;
      if (this.data_type == 1) {
         this._getAdminFieldIndex(this.table_options.cashOperation);
         
        getAdminBills(this.searchParams)
          .then((response) => {
            let data = response;
            if (data.code == 200) {
              let _data = data.data;
              this.total = _data.total;
              this.tableData = _data.list;
            }
            this.listLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.listLoading = false;
          });
      } else {
         this._getAdminFieldIndex(this.table_options.agency);

        getproxyBillsList(this.searchParams)
          .then((response) => {
            let data = response;
            if (data.code == 200) {
              let _data = data.data;
              this.total = _data.total;
              this.tableDataIncome = _data.list;
              this.tableData = [];
            }
            this.listLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.listLoading = false;
          });
      }
    },
    _putAdminBillsExamine(data) {
      this.listLoading = true;
      if (this.data_type == 1) {
        putAdminBillsExamine(data)
          .then((response) => {
            this.listLoading = false;
            if (response.code == 200) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.getList();
            } else {
              this.$message({
                message: response.msg,
                type: "error",
              });
            }
            this.listLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        examineProxyBill(data.id, data)
          .then((response) => {
            this.listLoading = false;
            if (response.code == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.getList();
            } else {
              this.$message({
                message: response.msg,
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.listLoading = false;
          });
      }
    },
    handleExportPayOrder() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      delete this.searchParams.page;
      delete this.searchParams.page_size;
      delete this.searchParams.start_at;
      delete this.searchParams.end_at;
      function objToUrl(obj) {
        const tempArray = [];
        Object.keys(obj).forEach((key) => {
          tempArray.push(`${key}=${obj[key]}`);
        });
        return tempArray.join("&");
      }
      this.searchParams.type = this.data_type;
      var str = objToUrl(this.searchParams);
      postAdminBillsExportIncome(str)
        .then((response) => {
          // if(response.code == 200){
          let data = response;
          loading.close();
          if (data) {
            const xlsx = "application/vnd.ms-excel";
            const blob = new Blob([data], {
              type: xlsx,
            });
            const a = document.createElement("a"); // 转换完成，创建一个a标签用于下载
            a.download =
              this.data_type == 1
                ? "格格健康平台订单支付明细表.xlsx"
                : "格格健康代理提现明细表.xlsx";
            a.href = window.URL.createObjectURL(blob);
            a.click();
            a.remove();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
          // }

          this.dialogVisible3 = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      await this.getList();
    },
    dateChange(val) {
      console.log(val);
      if (val && val.length) {
        // this.searchParams.start_at = val[0]
        // console.log(val[0])
        // this.searchParams.end_at = val[1]
        this.searchParams.created_at = `${val[0]},${val[1]}`;
      } else {
        this.searchParams.created_at = "";
      }
      this.getList();
    },
    handleSeeCashDetail(index, row) {
      console.log(row.id);
      this.idBill = row.id;
      this.isActiveSeeCashDetailVisible = true;
    },
    handleCashPass(row) {
      this.$confirm("是否通过审核?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(row);
          let data = {
            status: "Y",
            id: row.id,
          };
          this._putAdminBillsExamine(data);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleCashRefuse(row) {
      this.curRow = row;
      this.isActiveRefuseCashVisible = true;
    },
    handleConfirm() {
      if (this.refuseTextarea.length === 0) {
        this.$message({
          message: "请输入拒绝原因",
          type: "warning",
        });
      } else {
        let data = {
          status: "N",
          id: this.curRow.id,
          reject_reason: this.refuseTextarea,
        };
        this._putAdminBillsExamine(data);
        this.refuseTextarea = "";
        this.isActiveRefuseCashVisible = false;
      }
    },
    handleCancel() {
      this.refuseTextarea = "";
      this.isActiveRefuseCashVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-container {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;

  .order-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 200px;
    height: 50px;
    border-radius: 10px;
    text-align: center;
    border: 1px solid #e5e5e5;

    .text {
      color: #333;
      font-weight: 600;
    }

    .price {
      color: red;
    }
  }
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
